import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Cont } from "./styles";
import DateTimePicker from "../../components/DateTimePicker";

export const AS_OF_DATE= 'AS-OF-DATE'

const VestingTool = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [newDate, setNewDate] = useState("");
  const storedDate = localStorage.getItem(AS_OF_DATE);

  const handleUpdateDate = () => {
    if (newDate) {
        localStorage.setItem(AS_OF_DATE, newDate)
        axios.defaults.headers.common[AS_OF_DATE]= newDate
    };
    setNewDate("");
  };

  const handleChange = (d: string) => {
    setNewDate(d);
  };
  useEffect(() => {
    if (storedDate) {
      setStartDate(new Date(storedDate));
    }
  }, []);

  const isDisabled = !newDate || newDate === storedDate;
  return (
    <Cont>
      <h1>Vesting tool </h1>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div style={{width:'350px'}}>
        <DateTimePicker startDate={startDate} onDateChange={handleChange} />
        </div>
        <Button
          disabled={isDisabled}
          onClick={handleUpdateDate}
          variant="primary"
        >
          Update Date
        </Button>
      </div>
    </Cont>
  );
};

export default VestingTool;
