import { useGetExcelExportFlagQuery } from "../../api/rtkQuery/commonApi";
import { PillButton } from "./styles";
import ExcelIcon from "../../assets/icons/excel-icon.svg";

const ExportButton = () => {
  const { data: ExcelExportFlag } = useGetExcelExportFlagQuery();
  return (
    <div>
      {ExcelExportFlag?.is_active ? (
        <PillButton>
          <img src={ExcelIcon} alt="x" />
          Export
        </PillButton>
      ) : null}
    </div>
  );
};

export default ExportButton;
