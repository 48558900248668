import {INVESTOR_URL_PREFIX,MP_ORIGIN} from "../constants/routes";
import { advisorSelectedHexParam } from "./advisorSelected";

export const getHomepageUrl=(companyPrefix:string)=>{

    const mpParam = localStorage.getItem('mpParam');
    const InvestorOrigin= window.location.origin
    if(mpParam && MP_ORIGIN){
        return `${MP_ORIGIN}${companyPrefix}?${advisorSelectedHexParam()}`
    }
    else {
        return `${InvestorOrigin}${companyPrefix}/${INVESTOR_URL_PREFIX}/start`
    }
}