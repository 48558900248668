import styled from "styled-components";
import Col from "react-bootstrap/Col";


export const StatusPillCol = styled(Col)`
  text-align: center;
  z-index: 5; 
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`