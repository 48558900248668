import React, {FunctionComponent, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchFundInvestorDetail, fetchInvestorProfiles} from "./thunks";
import {selectFundInvestorDetail, selectInvestorProfileOptions} from "./selectors";
import InfoTable from "./components/InfoTable";
import {OptionTypeBase} from "react-select";
import TopSection from "./components/TopSection";
import {LoggedInFooter} from "../../components/Footer";
import NoDataToast from "../../components/NoDataToast";
import {useGetInvestmentDetailPageConfigQuery} from "../../api/rtkQuery/pageConfigsApi";
import {IInvestmentTable} from "../../interfaces/PageConfigs/investmentDashboard";
import {filterTables} from "../../utils/RenderTablesDynamically";
import NotificationsList from "../InvestorOwnership/components/Notifications";
import { uniq } from 'lodash';
import ExportButton from '../../components/ExportButton';
import { generateDateParamsFromRange } from '../../utils/dateFormatting';
import { useGetFundDetailsQuery } from '../../api/rtkQuery/fundsApi';


interface FundInvestorsProps {
}


const FundInvestorDetail: FunctionComponent<FundInvestorsProps> = () => {
  const {externalId} = useParams<{ externalId: string }>();
  const [investorProfile, setInvestorProfile] = useState<OptionTypeBase | null | undefined>(null);
  const [dateRange, setDateRange] = useState({startDate: null, endDate: null})
  const dispatch = useAppDispatch();
  const fundInvestments = useAppSelector(selectFundInvestorDetail);
  const investorProfileOptions = useAppSelector(selectInvestorProfileOptions);
  const {data: fundDetails} = useGetFundDetailsQuery(externalId)
  const {data: investorDetailPageConfig} = useGetInvestmentDetailPageConfigQuery(externalId)

  useEffect(() => {
    dispatch(fetchInvestorProfiles());
  }, [])

  useEffect(()=>{
   const dateParams = generateDateParamsFromRange(dateRange)
    dispatch(fetchFundInvestorDetail({externalId, dateParams}))
  },[dateRange])

  useEffect(() => {
    const availableInvestorIds = uniq(fundInvestments?.map(investment => investment.investor))
    if (!availableInvestorIds) return;

    let investorExists = false
    if (investorProfile) {
      investorExists = availableInvestorIds.indexOf(investorProfile.value) > -1;
    }
    if (!investorProfile || !investorExists) {
      const firstProfile = investorProfileOptions.find(p => p.value === availableInvestorIds[0])
      setInvestorProfile(firstProfile)
    }
  }, [investorProfileOptions, fundInvestments])

  if (!investorDetailPageConfig) {
    return <></>
  }

  const fundInvestorDetail = fundInvestments?.filter(investment => investment.investor === investorProfile?.value)
  if (!fundDetails) return <></>

  const availableInvestorIds =uniq(fundInvestments?.map(investment => investment.investor))
  const availableOptions = investorProfileOptions.filter(profile => availableInvestorIds.indexOf(profile.value) > -1)

  const currencySymbol = fundDetails?.currency?.symbol;
  return <>
    {!fundInvestorDetail[0]?.has_data && <NoDataToast/>}
    <TopSection
      dateRange={dateRange}
      setDateRange={setDateRange}
      fundInvestorDetail={fundInvestorDetail[0] || fundDetails}
      availableOptions={availableOptions}
      investorProfile={investorProfile}
      setInvestorProfile={setInvestorProfile}
    />
    {fundInvestorDetail[0] && <>
    {filterTables(investorDetailPageConfig.tables, fundInvestorDetail[0], fundInvestorDetail[0].is_legacy).map((detailTable: IInvestmentTable) => <section>
      <div className="d-flex justify-content-between">
      <h2 className="section-title">{detailTable.heading}</h2>
      <ExportButton/>
      </div>
      <InfoTable
        infoRows={fundInvestorDetail}
        // colWidth="20%"
        currencySymbol={currencySymbol}
        columnConfigs={detailTable.rows}
      />
    </section>)}
    <section>
      <h2 className="section-title">Notifications & Documents</h2>
      <NotificationsList
        key={`${investorProfile?.value}-${fundInvestorDetail[0]?.fund}`}
        viewInvestorId={investorProfile?.value}
        viewFundId={fundInvestorDetail[0]?.fund}
      />
    </section>
    </>}

    <LoggedInFooter/>
  </>
};

export default FundInvestorDetail;
