import React, {FunctionComponent, useEffect, useState} from "react";
import {ICarryDocument, ParticipantAllocation} from "../../../interfaces/carryManagement";
import  {ITileInfo} from "../../../components/InfoTileLayout";
import API from "../../../api/backendApi";
import {handleFormatToCurrency, limitCarryDecimalPlaces} from "../../../utils/currency";
import {Container} from "../../TaxForms/styles";
import {SideCarStyledTable} from "../../../presentational/StyledTableContainer";
import {CarryDocumentsViewProps} from "../index";
import {useHistory, useParams} from "react-router-dom";
import {SectionWrapper} from "../styles";
import NavableLoader from "../../../components/NavableLoader";
import SubInfoTileLayout from "../../../components/SubInfoTileLayout";
import VestingDetailModal from "./VestingDetailModal";
import RsSuite from "../../../components/Table/RSuite";
import {getAllocationsColumns, getDocumentsColumns} from "../constants";
import { Breadcrumb } from "react-bootstrap";
import {INVESTOR_URL_PREFIX} from "../../../constants/routes";
import {useCompanyPrefix} from "../../../utils/hooks";
import {TopRow} from "../../StartPage/styles";
import {standardizeDate} from "../../../utils/dateFormatting";
import get from "lodash/get";


const CarryAllocationDetail: FunctionComponent<CarryDocumentsViewProps> = () => {
    const {externalId} = useParams<{ externalId: string }>();
    const [carryDocuments, setCarryDocuments] = useState<ICarryDocument[]>([])
    const [allocations, setAllocations] = useState<ParticipantAllocation[]>([])
    const [InfoTileData, setInfoTileData] = useState<ITileInfo[][]>([[]])
    const [isLoadingOverview, setIsLoadingOverview] = useState<boolean>(false)
    const [carryPlanName, setCarryPlanName] = useState<string>("")
    const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false)
    const [isLoadingAllocations, setIsLoadingAllocations] = useState<boolean>(false)
    const [scheduleToView, setScheduleToView] = useState<null | number>(null);
    const history = useHistory()
    const {companyPrefix} = useCompanyPrefix()

    const handleCloseModal = () => {
        setScheduleToView(null);
    };

    const handleSelectScheduleToView = (scheduleId: number) => {
        return () => setScheduleToView(scheduleId);
    };


    const handleDocumentsFetch = async () => {
        const data = await API.getCarryDocumentsByCarryPlan(externalId)
        setCarryDocuments(data)
    }

    const handleAllocationsFetch = async () => {
        const data = await API.getCarryDocumentsByCarryPlan(externalId)
        setCarryDocuments(data)
    }

    const handleOverviewFetch = async () => {
        const data = await API.getCarryAllocationFromCarryPlan(externalId)
        const percent_pool= get(data, "points") / get(data, "carry_pool_points")
        const estimated_value = get(data, "carry_plan_estimated_value",0) * percent_pool
        const vested_value = get(data, "vested_points")/get(data, "points") * estimated_value
        const unvested_value = estimated_value-vested_value
        console.log(data)
        setCarryPlanName(data.carry_plan_name)
        setAllocations(data.allocations)
        const tileData:ITileInfo[][] = [
            [{value: handleFormatToCurrency(limitCarryDecimalPlaces(estimated_value)), label: "Estimated Total Value"},
            {value: handleFormatToCurrency(limitCarryDecimalPlaces(vested_value)), label: "Vested Estimated Total Value"},
            {value: handleFormatToCurrency(limitCarryDecimalPlaces(unvested_value)), label: "Unvested Estimated Total Value"},],
            [{value: limitCarryDecimalPlaces(data.points), label: "Points"},
            {value: limitCarryDecimalPlaces(data.vested_points), label: "Vested Points"},
            {value: limitCarryDecimalPlaces(data.unvested_points), label: "Unvested Points"},],
            [{value: data.vesting_plan,label: "Vesting Plan", onClick: handleSelectScheduleToView(data.vesting_schedule_id)},
            {value: standardizeDate(data.vesting_start_date), label: "Vesting Start Date"},]
        ]
        setInfoTileData(tileData)
    }

    useEffect(() => {
        setIsLoadingDocuments(true)
        handleDocumentsFetch().then(() => {
            setIsLoadingDocuments(false)
        });
    }, [])

    useEffect(() => {
        setIsLoadingOverview(true)
        setIsLoadingAllocations(true)
        handleOverviewFetch().then(() => {
            setIsLoadingOverview(false)
            setIsLoadingAllocations(false)
        });
    }, [])

    return <Container className={'mt-5'}>
        <div style={{paddingBottom: 20}} className={'mt-4'}><h2>Carry Dashboard</h2></div>
        <TopRow>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(`${companyPrefix}/${INVESTOR_URL_PREFIX}/carry-plans`)}>Carry Management</Breadcrumb.Item>
                {!isLoadingOverview && <Breadcrumb.Item>{carryPlanName}</Breadcrumb.Item>}
            </Breadcrumb>
        </TopRow>
        <SideCarStyledTable>
            <SectionWrapper className="mt-5">
                {isLoadingOverview && <NavableLoader></NavableLoader>}
                {!isLoadingOverview &&
                    <SubInfoTileLayout data={InfoTileData as ITileInfo[][]} colProps={{lg: 4, xs:12, md:4}}/>
                }
            </SectionWrapper>
            <SectionWrapper>
                <h3>
                    Allocations
                </h3>
                {isLoadingAllocations && <NavableLoader></NavableLoader>}
                {!isLoadingAllocations &&
                    <RsSuite
                        height="400px"
                        allowColMinWidth={true}
                        rowSelection={false}
                        columns={getAllocationsColumns()}
                        data={allocations}
                    />
                }
            </SectionWrapper>
            <SectionWrapper>
                <h3>
                    Notifications & Documents
                </h3>
                {isLoadingDocuments && <NavableLoader></NavableLoader>}
                {!isLoadingDocuments &&
                    <RsSuite
                        height="400px"
                        allowColMinWidth={true}
                        rowSelection={false}
                        columns={getDocumentsColumns()}
                        data={carryDocuments}
                    />
                }
            </SectionWrapper>
        </SideCarStyledTable>
        {scheduleToView && (
            <VestingDetailModal
                handleCloseModal={handleCloseModal}
                scheduleId={scheduleToView}
            />
        )}
    </Container>
}

export default CarryAllocationDetail