import React, {FunctionComponent, useEffect, useState} from "react";
import API from '../../api/backendApi'
import {Container} from "../TaxForms/styles";
import {SideCarStyledTable} from "../../presentational/StyledTableContainer";
import {ICarryDocument} from "../../interfaces/carryManagement";
import InfoTileLayout, {ITileInfo} from "../../components/InfoTileLayout";
import RsSuite from "../../components/Table/RSuite";
import {getAllocationColumns, getDocumentsColumns} from "./constants";
import {handleFormatToCurrency, limitCarryDecimalPlaces} from "../../utils/currency";
import {SectionHeading, SectionWrapper, TopHeading} from "./styles";
import NavableLoader from "../../components/NavableLoader";
import {useHistory} from "react-router-dom";
import {INVESTOR_URL_PREFIX} from "../../constants/routes";
import {useCompanyPrefix} from "../../utils/hooks";

export interface CarryDocumentsViewProps {
}

const CarryDocumentsSigningView: FunctionComponent<CarryDocumentsViewProps> = () => {
    const [carryDocuments, setCarryDocuments] = useState<ICarryDocument[]>([])
    const [allocations, setAllocations] = useState<ICarryDocument[]>([])
    const [InfoTileData, setInfoTileData] = useState<ITileInfo[]>([])
    const [isLoadingOverview, setIsLoadingOverview] = useState<boolean>(false)
    const [isLoadingAllocations, setIsLoadingAllocations] = useState<boolean>(false)
    const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false)
    const {companyPrefix} = useCompanyPrefix()
    const history = useHistory();

    const handleDocumentsFetch = async ()=> {
        const data = await API.getCarryDocuments()
        setCarryDocuments(data)
    }

    const handleAllocationsFetch = async ()=> {
        const data = await API.getCarryAllocations()
        setAllocations(data)
    }

    const redirectAllocationDetail = async (externalId : string)=> {
        history.push(`${companyPrefix}/${INVESTOR_URL_PREFIX}/carry-plans/${externalId}`);
    }

    const handleOverviewFetch = async ()=> {
        const data = await API.getCarryOverview()
        const tileData = [
            {value: data.total_estimated_value ? handleFormatToCurrency(limitCarryDecimalPlaces(data.total_estimated_value)) : '-', label: "Total Estimated Value"},
            {value:handleFormatToCurrency(limitCarryDecimalPlaces(data.estimated_vested_value)), label: "Estimated Vested Value"},
            {value:handleFormatToCurrency(limitCarryDecimalPlaces(data.estimated_unvested_value)), label: "Estimated Unvested Value"},
            {value:data.allocations_count, label: "Allocations"},
        ]
        setInfoTileData(tileData)
    }

    useEffect(() => {
        setIsLoadingDocuments(true)
        handleDocumentsFetch().then(() => {
            setIsLoadingDocuments(false)
        });
    }, [])

    useEffect(() => {
        setIsLoadingOverview(true)
        handleOverviewFetch().then(() => {
            setIsLoadingOverview(false)
        });
    }, [])

    useEffect(() => {
        setIsLoadingAllocations(true)
        handleAllocationsFetch().then(() => {
            setIsLoadingAllocations(false)
        });
    }, [])


    return <Container className={'mt-5'}>
       <TopHeading>Carry Dashboard</TopHeading>
        <SideCarStyledTable>
            <>
                <SectionWrapper className="mt-5">
                    {isLoadingOverview && <NavableLoader></NavableLoader>}
                    {!isLoadingOverview &&
                        <InfoTileLayout data={InfoTileData as ITileInfo[]} colProps={{lg: 3}}/>
                    }
                </SectionWrapper>
                <SectionWrapper>
                    <SectionHeading>
                        Allocations
                    </SectionHeading>
                    {isLoadingAllocations && <NavableLoader></NavableLoader>}
                    {!isLoadingOverview &&
                        <RsSuite
                            height="500px"
                            allowColMinWidth={true}
                            rowSelection={false}
                            columns={getAllocationColumns(redirectAllocationDetail)}
                            data={allocations}
                        />
                    }
                </SectionWrapper>
                <SectionWrapper>
                    <SectionHeading>
                        Notifications & Documents
                    </SectionHeading>
                    {isLoadingDocuments && <NavableLoader></NavableLoader>}
                    {!isLoadingDocuments &&
                        <RsSuite
                            height="400px"
                            allowColMinWidth={true}
                            rowSelection={false}
                            columns={getDocumentsColumns()}
                            data={carryDocuments}
                        />
                    }
                </SectionWrapper>
            </>
        </SideCarStyledTable>
    </Container>

}

export default CarryDocumentsSigningView