export const NO_PENDING_TASKS_TEXT = 'There are no pending tasks...'

export const OPPORTUNITIES_TEXT_DATA = {
    NOT_STARTED:{
        title:'Investment Opportunities',
        empty:'New investment opportunities coming soon...'
    },
    WITHDRAWN:{
        title:'Past Applications',
        empty:'There are no Withdrawn/Declined applications. They will appear here after the Application in Progress are finished.'
    }
}