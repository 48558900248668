import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./config";
import {INVESTMENT_HISTORIC_DATA, INVESTMENT_SUMMARY_DATE_FILTERING} from "../../constants/featureFlags";

export const api = createApi({
  baseQuery,
  reducerPath: "commonApi",
  tagTypes: [],
  endpoints: (build) => ({
    getCompanyInfo: build.query({
      query: (externalId) => `/funds/external_id/${externalId}/company_info`,
      keepUnusedDataFor: 0,
    }),
    getCurrencies: build.query({
      query: (externalId: string) => `currencies/fund/${externalId}`,
      keepUnusedDataFor: 0,
    }),
    getExcelExportFlag: build.query<any, void>({
      query: () => `/feature_flags/excel_export_flag`,
    }),
    getInvestmentSummaryDateFilteringFeatureFlag: build.query<any, void>({
      query: () => `/feature_flags/${INVESTMENT_SUMMARY_DATE_FILTERING}`,
    }),
    getPortfolioFilteringFeatureFlag: build.query<any, void>({
      query: () => `/feature_flags/${INVESTMENT_HISTORIC_DATA}`,
    }),
  }),
});

export const { useGetCurrenciesQuery, useGetCompanyInfoQuery, useGetExcelExportFlagQuery, useGetInvestmentSummaryDateFilteringFeatureFlagQuery , useGetPortfolioFilteringFeatureFlagQuery} = api;
