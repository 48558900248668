export const GLOSSARY_DEFINITION_HASH : {[key: string]: string} = {
  "% of Account": "Investment as a percentage of employee's total co-investment portfolio (Net Equity of Investment / Total Net Equity)",
  "Capital Calls Since Last NAV Update": "Total capital called by the investment from the investor since the last reported NAV of the investment ",
  "Capital Distributions": "Amount of capital returned by the investment to the investor",
  "Currency": "Currency of the investment",
  "Distributions Since Last NAV Update": "Total distributions from the investment to the investor made since the last reported NAV of the investment.",
  "Equity Commitment": "Equity obligation by investor to the investment.",
  "Equity Commitment Called to Date": "Amount of legal equity with respect to an investment which has been drawn from investor",
  "Equity Commitment Uncalled": "Uncalled equity investor has committed towards the investment",
  "Gross Commitment": "Investor's total attributable commitment to an investment, including the amount to be funded via loan ",
  "Gross Commitment Called to Date": "Total amount of legal equity and attributable loan drawn from investor since inception.",
  "Gross Commitment Uncalled": "Investor's remaining attributable commitment to an investment, including both the amount to be funded via equity and attributable loan",
  "Gross Distributions Recallable to Date": "Total amount of capital returned by the investment to the investor that can be used for future capital calls during the investment period.",
  "Net Commitment Called to Date": "Total amount of legal equity and attributable loan contributions less amount of capital returned by investment that can be used for future capital calls during the investment period.",
  "Gross Share of NAV": "Investor's share of Investment NAV. It is adjusted to include capital calls since latest published NAV, less distributions since latest published NAV",
  "Initial Leverage Ratio": "The loan to value for the investment at the time of subscription",
  "Interest Rate": "The latest annually determined interest rate for the loan.",
  "Invested Since": "Value date of Investor's first capital call date for the investment",
  "Investment": "The fund, separate account mandate or other investment that the employee is investing in.",
  "Investment NAV": "The latest available NAV of the underlying investment.",
  "Investment NAV as of (date)": " Date of the investment NAV shown in the investor portal.",
  "Investment Ownership %": "The ownership percentage of an investor in an investment",
  "Investor ": "Name of the employee or vehicle through which the employee is investing",
  "Loan Balance": "Balance of attributable loan with respect to the investment",
  "Loan Drawn": "The amount of the loan commitment that has been called by the manager.",
  "Loan Balance + Unpaid Interest": "Balance of attributable loan provided plus the balance of interest due.",
  "Loan Commitment": "Total attributable loan commitment to an investment ",
  "Loan to Value": "The value of leverage for the investment divided by the gross share of NAV.",
  "Loan Repayment": "The amount of the drawn loan that has been repaid by the investor.",
  "Net Equity": "NAV of the Investment attributable to the employee net of the employee's attributable share of unpaid loans and interest",
  "Total Equity Commitment Uncalled": "Total equity remaining to be called across all investments.",
  "Total Gross Share of NAV": "Investor's share of Investment NAV across all investments in their portfolio.",
  "Total Interest Accrued to Date": "The total amount of interest payable on attributable loans since inception.",
  "Total Interest Paid to Date": "Total amount of interest paid on attributable loans with respect to the investment.",
  "Total Loan Balance + Unpaid Interest": "Total balance of attributable loans provided plus the balance of interest due across all investments.",
  "Total Net Equity": "NAV of the investor portfolio net of the employee's attributable share of total unpaid loans and interest across all investments. It is adjusted to include capital calls since latest published NAV, less distributions since latest published NAV.",
  "Total Unrealized Gain/(Loss)": "Valuation less cost basis across all active investments",
  "Unpaid Interest": "Total interest outstanding on attributable loan with respect to the investment",
  "Unrealized Gain/(Loss)": "Valuation of investment less cost basis of investment.",
  "Years Invested": "Duration of investor's investment, calculated since date of first capital call from the investor",
  "End of Investment Commitment Period": "The date after which the investor's commitment is no longer legally obligated to be called.",
  "Return of Capital": "Distribution that is composed of the investor's original equity investment.",
  "Profit Distributions": "Distribution that is above and beyond the investor's original equity investment.",
  "IRR Leveraged": "Coming Soon",
  "IRR Unleveraged" : "Coming Soon",
  "IRR Leveraged / Unleveraged": "Coming Soon",
  "Distributions from Underlying Investment": "Return of capital and profit distributions from underlying fund into the feeder/program attributable to the employee",
  "Distributions Used to Repay Loan": "Distribution proceeds that have been used to reduce the balance of the employee's attributable loan.",
  "Distributions Used to Repay Interest": "Investment distribution proceeds that have been used to reduce the balance of the employee's interest expense over the life of the investment.",
  "Distributions Recallable": "Investment distributions that are able to be called again by the manager for purposes of investing.",
  "Distributions Paid to Employee": "Amount distributed to employee.",
  "Total IRR Leveraged / Unleveraged": "Coming Soon"
}