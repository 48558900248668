import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { ThemeButton } from "../../../../components/Button/styles";

export const FormContent = styled.div`
    font-size: 16px;
    font-weight: 500;
    text-align: left;

`

export const EligibilityInterestForm = styled.div`
    padding-top: 24px;
    .interest-form{
        background: #FFF;
        padding: 0;
        input[type="text"], select, .select__control{
            width: 100%;
        }
    }
  
    .custom-radio-buttons {
      .form-check-inline {
        padding: 8px !important;
        label {
          display: flex !important;
        }
        input {
          padding: 8px !important;
        }
      }
    }
`

export const NextButton = styled(ThemeButton)`
    margin-right: 20px;
    margin-bottom: 8px;
`

export const WideNextButton = styled(ThemeButton)`
  padding: 16px 40px;
`


export const FinalInvestmentAmountDiv = styled.div`
  padding: 0;
  background: inherit;
  width: 100%;
`