import styled from "styled-components";
import Form from "react-bootstrap/Form";

export const AcknowledgmentCheckbox = styled(Form.Check)`
  background: #FFFFFF !important;
  margin-left: 24px;
  font-size: 14px;

`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
