import {IOwnershipFundInvestor} from "../../../../interfaces/investorOwnership";
import {TOTAL_ROW_ID} from "./computations";
import React from "react";
import styled from "styled-components";
import {INVESTOR_URL_PREFIX} from "../../../../constants/routes";
import {FundDetailLink} from "../../../../presentational/Links";
import {DASH_DEFAULT_VALUE, NA_DEFAULT_VALUE} from "../../../../constants/defaultValues";
import {IInvestmentStat} from "../../../../interfaces/PageConfigs/investmentDashboard";
import {filterColumns, getDisplayValue} from "../../../../utils/RenderTablesDynamically";
import { get } from "lodash";


const FirstColSpan = styled.span`
  display: inline-flex;
`

const TableLogo = styled.img`
  margin-right: 10px;
  width: 80px;
  height: 100%;
  max-height: 46px;
`


export const getDynamicActiveInvestmentColumns = (
  showUSD: boolean,
  isLegacy: boolean,
  columns: IInvestmentStat[],
  companyPrefix: string
) => {

  const filteredData = filterColumns(columns, isLegacy)
  const filteredColumns: any[] = [
    {
      title: "",
      dataKey: "company_logo",
      flexGrow: 1.5,
      minWidth: 130,
      Cell: (row: IOwnershipFundInvestor) => {
        if (row.isSubRow) return <></>
        const isTotalRow = row.id === TOTAL_ROW_ID;
        const logo = row.fund?.logo ? row.fund.logo : row.company_logo;
        return <>
          {isTotalRow ? 'Total' : <FirstColSpan>
            {logo && <TableLogo src={logo} alt=""/>}
          </FirstColSpan>}
        </>
      },
    },
  ]
  filteredData.forEach((investmentDetailCol: IInvestmentStat) => {
    filteredColumns.push({
      title: investmentDetailCol.heading,
      dataKey: investmentDetailCol.field_name,
      flexGrow: 1.5,
      minWidth: 130,
      isSortable: investmentDetailCol.is_sortable,
      tooltip: investmentDetailCol.tooltip,
      Cell: (row: IOwnershipFundInvestor) => {
        if (investmentDetailCol.link_to_investment_detail) {
          if (row.isSubRow) return <>{row.investor_name}</>
          const isTotalRow = row.id === TOTAL_ROW_ID;
          if (isTotalRow) return <></>
          return <FundDetailLink
            to={`${companyPrefix}/${INVESTOR_URL_PREFIX}/funds/${row.fund.external_id}/detail`}>{get(row,investmentDetailCol.field_name)}
          </FundDetailLink>
        }
        const currencySymbol = showUSD ? '$' : row.currency.symbol;
        const currencyRate = showUSD ? row.currency.rate : 1;
        return getDisplayValue(
          row,
          investmentDetailCol,
          currencySymbol,
          currencyRate
        )
      }
    })
  })
  return filteredColumns;
}
