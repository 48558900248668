import styled from "styled-components";

export const CarryDocumentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 24px;
  border: 1px solid #C1CEE9;
  padding: 24px;
  margin-bottom: 1.5rem;
`