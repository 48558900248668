import {CarryPlan, ICarryDocument} from "../../interfaces/carryManagement";
import {StatusPill} from "./styles";
import FilePreviewModal from "../../components/FilePreviewModal";
import {StyledCheckbox} from "../FundDocuments/styles";
import Form from "react-bootstrap/Form";
import React from "react";
import {handleFormatToCurrency, limitCarryDecimalPlaces, limitDecimalPlaces} from "../../utils/currency";
import {get} from "lodash";
import {standardizeDate} from "../../utils/dateFormatting";


export const getPillColor=(status:string)=>{
    let color = ''
    switch (status.toLowerCase()) {
        case 'active':
            color = '#10AC84'
            break;
        case 'draft':
            color ='#FF8A00'
            break;
        case 'review':
            color= '#3A8DDF'
            break;
        case 'inactive':
            color= '#FF5722'
            break;
        case 'pending acknowledgement':
        case 'acknowledgement':
            color= '#2E86DE'
            break;
        case 'signed':
        case 'signature':
            color= '#10AC84'
            break
    }
    return color
}

export const getAllocationColumns = (handleSelectCarryPlanAllocation: (arg1: string) => any) => [
    {
        title: "Name",
        fixed: "left",
        minWidth: 200,
        flexGrow: 1,
        isSortable:true,
        Cell: (row: CarryPlan) => (
            <span
                style={{
                    cursor: "pointer",
                    fontWeight: 900,
                    textDecoration: "underline",
                }}
                onClick={() => handleSelectCarryPlanAllocation(row.external_id)}
            >
        {row.carry_plan_name}
      </span>
        ),
    },
    {
        title: "Points",
        minWidth: 170,
        flexGrow: 1.25,
        Cell: (row: CarryPlan) => (
            <span>{limitDecimalPlaces(row.points, 4)}</span>
        ),
    },
    {
        title: "Unvested",
        minWidth: 150,
        flexGrow: .8,
        Cell: (row: CarryPlan) => <span>{limitDecimalPlaces(row.unvested_points, 4)}</span>,
    },
    {
        title: "Vested Points",
        minWidth: 150,
        flexGrow: .8,
        Cell: (row: CarryPlan) => <span>{limitDecimalPlaces(row.vested_points, 4)}</span>,
    },
    {
        title: "% Pool",
        minWidth: 150,
        flexGrow: .8,
        Cell: (row: CarryPlan) => <span>{limitDecimalPlaces(row.pool_percentage, 4)}</span>,
    },
];

export const getDocumentsColumns = () => [
    {
        title: "Document",
        fixed: "left",
        dataKey: "name",
        minWidth: 200,
        flexGrow: 1,
        isSortable:true,
        Cell: (row: ICarryDocument) => (
            <span
                style={{
                    cursor: "pointer",
                    fontWeight: 900,
                    textDecoration: "underline",
                }}
            >
              <FilePreviewModal
                  documentId={`${row.document.document_id}`}
                  documentName={row.document.title}
                  showPreviewIcon={false}
                  callbackPreviewFile={() => {}}
                  callbackDownloadFile={() => {}}
                  showAsButton={false}
                  showDownloadIcon={false}
                  dotted={false}
              />
            </span>
        ),
    },
    {
        title: "Recipient",
        minWidth: 170,
        flexGrow: 1.25,
        Cell: (row: ICarryDocument) => (
            <span>{row.display_name}</span>
        ),
    },
    {
        title: "Description",
        minWidth: 170,
        flexGrow: 1.25,
        Cell: (row: ICarryDocument) => (
            <span>{row.carry_document_description}</span>
        ),
    },
    {
        title: "Requirements",
        minWidth: 150,
        flexGrow: .8,
        Cell: (row: ICarryDocument) => (
            <StatusPill color={getPillColor(row.status)}>
                {row.status}
            </StatusPill>
        ),
    },
    {
        title: "Type",
        minWidth: 150,
        flexGrow: .8,
        Cell: (row: ICarryDocument) => <span>{row.document_type_display}</span>,
    },
];


export const getAllocationsColumns = () => [
    {
        title: "Carry Recipient",
        dataKey: "carry_recipient_name",
        minWidth: 200,
        flexGrow: 1.3,
    },
    {
        title: "Estimated Value",
        dataKey: "estimated_value",
        minWidth: 150,
        flexGrow: 1,
        Cell: (row: any) => (
            <span>
        {handleFormatToCurrency(
            limitCarryDecimalPlaces(  get(row, "estimated_value"))
        )}
      </span>
        ),
    },
    {
        title: "Vested Value",
        dataKey: "vested_value",
        minWidth: 150,
        flexGrow: 1,
        Cell: (row: any) => (
            <span>
        {handleFormatToCurrency(
            limitCarryDecimalPlaces(get(row, "vested_value"))
        )}
      </span>
        ),
    },

    {
        title: "Unvested Value",
        dataKey: "unvested_value",
        minWidth: 150,
        flexGrow: 1,
        Cell: (row: any) => (
            <span>
        {handleFormatToCurrency(
            limitCarryDecimalPlaces(get(row, "unvested_value"))
        )}
      </span>
        ),
    },
    {
        title: "Vesting Start Date",
        dataKey: "vesting_start_date",
        width: 200,
        flexGrow: 1.5,
        Cell: (row: any) => (
            <span>
            {standardizeDate(get(row,"vesting_start_date"))}
          </span>
        ),
    },
    {
        title: "Points",
        dataKey: "bps",
        minWidth: 100,
        flexGrow: 0.6,
    },
    {
        title: "Unvested",
        dataKey: "unvested_bps",
        minWidth: 100,
        flexGrow: 0.6,
        Cell: (row: any) => (
            <span>{limitCarryDecimalPlaces(get(row, "bps") - get(row, "vested_bps"))}</span>
        ),
    },
    {
        title: "Vested Points",
        dataKey: "vested_bps",
        minWidth: 100,
        flexGrow: 0.6,
        Cell: (row: any) => (
            <span>{limitCarryDecimalPlaces(get(row,"vested_bps"))}</span>
        ),
    },
    {
        title: "% Pool",
        dataKey: "percent_pool",
        minWidth: 100,
        flexGrow: 0.6,
        Cell: (row: any) => (
            <span>{limitCarryDecimalPlaces(get(row, "percent_pool") * 100)}</span>
        ),
    },
];