const RightArrow = ({ color }: any) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.707 7.82364C14.0977 7.36808 14.0977 6.62827 13.707 6.17272L8.70674 0.341663C8.31609 -0.113888 7.68168 -0.113888 7.29103 0.341663C6.90038 0.797215 6.90038 1.53703 7.29103 1.99258L10.5881 5.83379H1.00006C0.4469 5.83379 0 6.35494 0 7C0 7.64506 0.4469 8.16621 1.00006 8.16621H10.585L7.29416 12.0074C6.90351 12.463 6.90351 13.2028 7.29416 13.6583C7.6848 14.1139 8.31921 14.1139 8.70986 13.6583L13.7101 7.82728L13.707 7.82364Z"
        // fill="#776697"
        fill={color}
      />
    </svg>
  );
};

RightArrow.defaultProps = {
  color: "#0000",
};

export default RightArrow;
